import React from 'react';
// import './App.css';
import { Route } from 'react-router-dom';
import Down from './components/page/Down';
import Down2 from './components/page/Down2';

import Main from './components/page/Main';

import ScrollToTop from './components/util/ScrollToTop';

function App() {
  return (
    <div>
      <ScrollToTop>
        <Route exact path="/" component={Down2} />
      </ScrollToTop>
    </div>
  );
}

export default App;
