import React from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Spin, Button, } from "antd";
import { withAlert } from 'react-alert';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';

const FormItem = Form.Item;

class Down2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
        <div style={{fontSize: '30px', padding: '100px'}}>
          <a href='https://kr.object.ncloudstorage.com/nnboxfr/installer/nnbfr_UpdaterSetup.msi'>상점EXE 다운로드</a>

        </div>
    );
  }
}

export default withAlert()(Down2);
